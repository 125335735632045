export default function useDocumentHelpers() {
  const directSignStore = useDirectSignStore()
  const visualSignatureStore = useVisualSignatureStore()
  const { directSignB64AuthHeader, isDirectSign } = storeToRefs(directSignStore)

  const { documentRepository, directDocumentRepository } = useApi()

  const { http } = useRuntimeConfig().public

  const getPagePreviewUrl = (documentId: number | string, page: number, scale: number): string => {
    const params = new URLSearchParams()
    params.append('scale', scale.toString())

    if (isDirectSign.value) {
      params.append('token', directSignB64AuthHeader.value)
      return `${http.browserBaseURL}/v3/direct/documents/${documentId}/pages/${page}?${params.toString()}`
    } else {
      return `${http.browserBaseURL}/v3/documents/${documentId}/pages/${page}?${params.toString()}`
    }
  }

  const getPagePreview = async (documentId: string, pageNumber: number, scale = 20) => {
    if (directSignStore.isDirectSign) {
      const token = directSignStore.directSignB64AuthHeader
      return await directDocumentRepository.getDocumentPage(documentId, pageNumber, { scale, token })
    } else {
      return await documentRepository.getDocumentPage(documentId, pageNumber, scale)
    }
  }

  const getItemPosition = (signature: PositionedSignature, pageNumber: number): SignaturePosition => ({
    page: String(pageNumber - 1), // Backend zero-indexes pages and expects a string
    x: signature.x,
    y: signature.y,
    width: signature.width,
    height: signature.height,
  })

  /**
   * Maps the visual signatures on a document to the corresponding signature placements our backend expects.
   *
   * @param placementMap The placement map to use as a reference.
   */
  const mapVisualSignatures = (placementMap: Map<number, Map<string, PositionedSignature>>): SignaturePosition[] => {
    return [...placementMap.entries()].flatMap(([pageNumber, signatures]) => {
      return [...signatures.values()].map(signature => getItemPosition(signature, pageNumber))
    })
  }

  const mapSeals = (placementMap: Map<number, PositionedSignature[]>): SignaturePosition[] => {
    return [...placementMap.entries()].flatMap(([pageNumber, seals]) => {
      return [...seals.values()].map(seal => {
        const { width, height } = visualSignatureStore.fitSealDimensions(seal.sealId ?? '', {
          width: seal.width,
          height: seal.height,
        })

        return getItemPosition({ ...seal, width, height }, pageNumber)
      })
    })
  }

  return {
    getPagePreviewUrl,
    getPagePreview,
    getItemPosition,
    mapVisualSignatures,
    mapSeals,
  }
}
